.skin-card {
  float: left;
  width: 33.33333%;
  padding: 5px;
  .skin-preview {
    display: block;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    .top-left {
      display: block;
      width: 20%;
      float: left;
      height: 7px;
    }
    .top-right {
      display: block;
      width: 80%;
      float: left;
      height: 7px;
    }
    .bottom-left {
      display: block;
      width: 20%;
      float: left;
      height: 20px;
    }
    .bottom-right {
      display: block;
      width: 80%;
      float: left;
      height: 20px;
    }
  }
  &.skin-card-light {
    font-size: 11px;
  }
}
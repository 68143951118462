@import "bootstrap/dist/css/bootstrap.css";
@import "font-awesome/scss/font-awesome.scss";
@import "ionicons/scss/ionicons.scss";
@import "./AdminLTE.scss";
@import "./skins/_all-skins.css";

html,
body {
  margin: 0px;
}

a {
  cursor: pointer;
}

#root {
  height: 100%;
}

.control-sidebar-bg {
  position: absolute;
}

.content-wrapper {
  min-height: calc(100vh - 50px);
}

.file-cover {
  max-height: 80px;
  max-width: 80px;
}

.row-control-btns {
  .btn:not(:last-child) {
    margin-right: 4px;
  }
}